import Alpine from "alpinejs";
import Pikaday from "pikaday";

document.addEventListener('alpine:init', () => {
    Alpine.data('datePicker', () => ({
            startDate: '',
            endDate: '',
            startPicker: null,
            endPicker: null,

            startDatePicker() {
                this.startPicker = new Pikaday({
                    field: this.$refs.inputStart,
                    onSelect: () => {
                        this.startDate = this.startPicker.getDate();
                        this.updateStartDate();
                    },
                    format: 'DD/MM/YYYY',
                    i18n: {
                        previousMonth : 'Mês anterior',
                        nextMonth     : 'Próximo mês',
                        months        : ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
                        weekdays      : ['Domingo','Segunda-feira','Terça-feira','Quarta-feira','Quinta-feira','Sexta-feira','Sábado'],
                        weekdaysShort : ['Dom','Seg','Ter','Qua','Qui','Sex','Sab']
                    }
                })
                const _startDate = this.startPicker.getDate()

                if (_startDate) {
                    this.startDate = _startDate;
                    this.updateStartDate();
                }

            },
            endDatePicker() {
                this.endPicker = new Pikaday({
                    field: this.$refs.inputEnd,
                    onSelect: () => {
                        this.endDate = this.endPicker.getDate();
                        this.updateEndDate();
                    },
                    format: 'DD/MM/YYYY',
                    i18n: {
                        previousMonth : 'Mês anterior',
                        nextMonth     : 'Próximo mês',
                        months        : ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
                        weekdays      : ['Domingo','Segunda-feira','Terça-feira','Quarta-feira','Quinta-feira','Sexta-feira','Sábado'],
                        weekdaysShort : ['Dom','Seg','Ter','Qua','Qui','Sex','Sab']
                    }
                })

                const _endDate = this.endPicker.getDate()
                if (_endDate) {
                    this.endDate = _endDate;
                    this.updateEndDate();
                }
            },
            updateStartDate() {
                this.startPicker.setStartRange(this.startDate);
                this.endPicker.setStartRange(this.startDate);
                this.endPicker.setMinDate(this.startDate);
            },
            updateEndDate() {
                this.startPicker.setEndRange(this.endDate);
                this.startPicker.setMaxDate(this.endDate);
                this.endPicker.setEndRange(this.endDate);
            },
            resetDatePickers() {
                this.startDate = '';
                this.endDate = '';

                this.startPicker.destroy();
                this.endPicker.destroy();

                this.updateStartDate();
                this.updateEndDate();

                this.startDatePicker()
                this.endDatePicker()
            }
        })
    )
});
