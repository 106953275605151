require('./bootstrap');
require('livewire-sortable');
import Pikaday from 'pikaday';

window.Pikaday = Pikaday;

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import persist from '@alpinejs/persist'

window.Alpine = Alpine

Alpine.plugin(persist)
Alpine.plugin(collapse)

require('./datePickerRange');

Alpine.start()

import Splide from '@splidejs/splide/dist/js/splide.js';

window.Splide = Splide;

import ApexCharts from 'apexcharts'

window.ApexCharts = ApexCharts

import iro from '@jaames/iro'

window.iro = iro

import Sortable from "sortablejs/modular/sortable.core.esm.js";
window.Sortable = Sortable;
